import React from 'react'

export default () => (
  <section className="section">
    <div className="container">
      <h3>PRIVACY POLICY</h3>
      <div>
        <p>We respect your privacy. Seriously, we do.</p>

        <p>
          We allow players to login through Facebook to make it easy for them to
          compete for high scores and send each other game items like lives and
          keys. To help us make the game better, we collect anonymous usage
          information like level progression and high scores.
        </p>
        <p>
          For Facebook players, we associate an app specific Facebook ID so we
          can restore your game progress and your friends can see your high
          scores.
        </p>
        <ul>
          <li>We do NOT post anything on your timeline.</li>
          <li>
            We do NOT collect your name, phone number, gender, or other profile
            information.
          </li>
          <li>
            We do NOT collect your picture or pictures of your friends on our
            servers. These are downloaded and shown only in the game.
          </li>
        </ul>
      </div>
    </div>
  </section>
)
